<template>
  <div class="Device">
    <simple-c-u-r-d
      ref="curd"
      search-url="/api/inspectDevice/list"
      add-or-update-url="/api/inspectDevice/addOrUpdate"
      delete-url="/api/inspectDevice/delete"
      :show-form="showForm"
      :model="addForm"
      :add-form-rules="addFormRules"
      is-pageable
      label-width="100px"
      :format-commit-data="formatCommitData"
      :show-add-button="()=>show"
      :show-delete-button="(row)=>show && row.id > 5"
      :show-edit-button="()=>show"
      :show-operation="()=>show"
    />
  </div>
</template>

<script>
import SimpleCURD from '@/components/simple/SimpleCURD'

export default {
  name: 'Device',
  components: { SimpleCURD },
  inject: ['user', 'formatCommitData'],
  data() {
    return {
      showForm: [
        { param: 'name', name: '类型名称', maxlength: '100', search: true, clearable: true },
      ],
      addForm: { name: null },
      addFormRules: {
        name: [{ required: true, message: '请输入类型名称', trigger: 'blur' }],
      },
      deviceType: [],
      show: null
    }
  },
  mounted() {
    const auth = this.user().role.authority.split(',')
    if (!auth.includes('AUTH_DEVICE')) {
      this.show = false
    } else {
      this.show = true
    }
  },
}
</script>

<style scoped>

</style>
