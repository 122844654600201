<script>
export default {
  props: {
    fullscreen: { type: Boolean, required: false, default: () => { return window.innerWidth < 800 } },
    closeOnClickModal: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    changeVisible() {
      this.visible = true
    },
    close() {
      this.visible = false
      this.$emit('handleClose')
    },
  },
  render(h) {
    const myBind = {
      props: {
        ...this.showForm,
        ...this.$attrs
      },
      on: {
        ...this.$listeners,
        close: this.close
      }
    }
    return <el-dialog ref="dialog" close-on-click-modal={this.closeOnClickModal} append-to-body {...myBind} fullscreen={this.fullscreen} visible={this.visible}>{this.$slots.default}</el-dialog>
  }
}
</script>
