export default {
  /**
   * 删除对象所有属性
   * @param obj
   * @returns {boolean}
   */
  deleteField(obj) {
    if (Object.prototype.toString.call(obj) !== '[object Object]') {
      return false
    }
    for (const key in obj) {
      delete obj[key]
    }
    return true
  },
  /**
   * 清除对象所有属性
   * @param obj
   * @returns {boolean}
   */
  clearField(obj) {
    if (Object.prototype.toString.call(obj) !== '[object Object]') {
      return false
    }
    for (const key in obj) {
      obj[key] = null
    }
    return true
  },
  /**
   * 清除对象指定属性
   * @param obj
   * @param index
   * @returns {boolean}
   */
  clearFieldByIndex(obj, ...index) {
    if (Object.prototype.toString.call(obj) !== '[object Object]') { return false }
    for (const key in obj) {
      if (index.includes(key)) {
        delete obj[key]
      }
    }
    return true
  },
  /**
   * src对象属性复制到target对象中
   * @param src
   * @param target
   */
  copyProperties(src, target) {
    for (const key in src) {
      target[key] = src[key]
    }
  },
  /**
   * 获得对象属性
   * @param obj
   * @param param
   * @returns {*}
   */
  getField(obj, param) {
    param = param.split(':')
    let temp = obj
    for (const k in param) {
      temp = temp[param[k]]
    }
    return temp
  },
}
