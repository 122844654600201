<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <simple-form ref="simpleCURDAddForm" :model="searchAddForm" inline :show-form="searchShowForm" current-type="search">
        <template #editRow="{row}">
          <slot name="editRow" :row="row" />
        </template>
        <template #default>
          <el-form-item>
            <el-button v-if="searchShowForm" :loading="$store.state.isLoading" type="primary" plain size="medium" @click="search">
              查询
            </el-button>
            <el-button v-if="addOrUpdateUrl&&showAddButton()" type="primary" plain size="medium" @click="handleAdd">
              新增
            </el-button>
            <slot name="searchOperation" :data="params" :selection="selection" />
          </el-form-item>
        </template>
      </simple-form>
    </el-col>
    <!--列表-->
    <simple-table :data="params" :handle-edit="handleEdit"
                  :handle-delete="handleDelete"
                  :show-form="showForm"
                  :operation-width="operationWidth"
                  :show-operation="()=>{return showOperation()&&(addOrUpdateUrl||deleteUrl)}"
                  :can-edit="row=>addOrUpdateUrl&&showEditButton(row)"
                  :can-delete="row=>deleteUrl&&showDeleteButton(row)"
                  :is-selection="isSelection"
                  :selectable="selectable"
                  :delete-name="deleteName"
                  @selection="handleSelection"
    >
      <template #operation="{row}">
        <slot name="operation" :row="row" />
      </template>
    </simple-table>
    <!--工具条-->
    <el-col v-if="isPageable" :span="24" class="toolbar">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="searchAddForm.size"
        :current-page.sync="searchAddForm.page"
        :page-sizes="[5, 10, 20]"
        :total="total"
        style="float: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-col>

    <!--新增界面-->
    <simple-dialog v-if="addOrUpdateUrl" ref="dialog" title="编辑" :close-on-click-modal="closeOnClickModal" @handleClose="handleEsc">
      <simple-form ref="simpleCURDAddForm" :model="model" :label-width="labelWidth" :show-form="addOrUpdateForm" :rules="addFormRules"
                   :current-type="selectType"
      >
        <template #editRow="{row}">
          <slot name="editRow" :row="row" />
        </template>
      </simple-form>
      <div class="NumFooter">
        <el-button @click.native="$refs.dialog.close()">
          取消
        </el-button>
        <el-button v-if="!onlyRead()" type="primary" :loading="$store.state.isLoading" @click.native="addOrUpdate">
          提交
        </el-button>
      </div>
    </simple-dialog>
  </section>
</template>

<script>
import objectUtils from '@/common/js/objectUtils'
import SimpleForm from './SimpleForm'
import SimpleTable from './SimpleTable'
import SimpleDialog from './SimpleDialog'

export default {
  components: { SimpleDialog, SimpleTable, SimpleForm },
  props: {
    labelWidth: { type: String, required: false, default: '60px' },
    model: { type: Object, required: false, default: () => {} },
    showForm: { type: [Object, Array], required: false, default: () => {} },
    addFormRules: { type: Object, default: () => {}, required: false },
    name: { type: String, default: null, required: false },
    searchUrl: { required: true, type: String },
    isSelection: { required: false, type: Boolean, default: false },
    selectable: { required: false, type: Function, default: () => {} },
    deleteUrl: { required: false, type: String, default: null },
    addOrUpdateUrl: { required: false, type: String, default: null },
    isPageable: { required: false, type: Boolean, default: false },
    isSearch: { required: false, type: Boolean, default: true },
    closeOnClickModal: { required: false, type: Boolean, default: false },
    onlyRead: { required: false, type: Function, default: () => false },
    operationWidth: { required: false, type: String, default: '200px' },
    showOperation: { required: false, type: Function, default: () => true },
    showEditButton: { required: false, type: Function, default: () => true },
    showDeleteButton: { required: false, type: Function, default: () => true },
    deleteName: { required: false, type: String, default: '删除' },
    showAddButton: { required: false, type: Function, default: () => true },
    formatCommitData: { required: false, type: Function, default: (item) => item },
    formatSearchParam: { required: false, type: Function, default: (item) => item },
    formatAddForm: { required: false, type: Function, default: (item) => item },
    keys: { type: Array, required: false, default: () => [] },
  },
  data() {
    return {
      params: [], /// 查询结果
      searchAddForm: {
        page: 1,
        size: 10,
      },
      selectType: 'search',
      selection: null,
      total: 0, // 分页总数
    }
  },
  computed: {
    searchShowForm() {
      return this.showForm.filter(item => { if (item.search) { return true } else { return false } })
    },
    addOrUpdateForm() {
      return this.showForm.filter(item => {
        const temp = Object.prototype.toString.call(item.add) === '[object Function]' ? item.add() : item.add
        if (typeof temp === 'undefined') {
          return true
        }
        return temp
      })
    }
  },
  mounted() {
    if (this.isSearch) {
      this.search()
    }
  },
  methods: {
    search() {
      let param = {}
      if (this.isPageable) {
        param = Object.assign({}, this.searchAddForm, { page: this.searchAddForm.page - 1 })
      }
      for (const key of this.keys) {
        if (!param[key.param]) {
          this.$OneMessage.warning(key.message)
          return
        }
      }
      return this.$get(this.searchUrl, this.formatSearchParam(param)).then(result => {
        if (this.isPageable) {
          const f = this.formatCommitData(result.data.content)
          if (f && f[Symbol.toStringTag] === 'Promise') {
            f.then(result => {
              this.params = result
            })
          } else {
            this.params = f
          }
          this.total = result.data.totalElements
        } else {
          const f = this.formatCommitData(result.data)
          if (f && f[Symbol.toStringTag] === 'Promise') {
            f.then(result => {
              this.params = result
            })
          } else {
            this.params = f
          }
          this.params = result.data
        }
        this.$emit('search', this.params)
      })
    },
    handleAdd() {
      this.selectType = 'add'
      this.$refs.dialog.changeVisible()
      this.$refs.simpleCURDAddForm.$refs.simpleForm.clearValidate()
      this.$emit('add', this.model)
    },
    handleEdit(row) {
      this.selectType = 'update'
      objectUtils.copyProperties(row, this.model)
      this.$refs.dialog.changeVisible()
      this.$emit('edit', row)
    },
    handleDelete(row) {
      let name = ''
      if (this.name) {
        name = row[this.name]
      }
      this.$confirm('确认删除' + name + '吗', '确认删除').then(() => {
        this.$emit('beforeDelete')
        this.$post(this.deleteUrl, row).then(result => {
          if (!this.$listeners.afterDelete) {
            this.search()
          }
          this.$emit('afterDelete', row, this.search)
        })
      }).catch(() => {})
    },
    handleEsc() {
      this.selectType = 'search'
      objectUtils.clearField(this.model)
    },
    addOrUpdate() {
      this.$refs.simpleCURDAddForm.$refs.simpleForm.validate(item => {
        if (item) {
          this.$emit('beforeAddOrUpdate')
          this.$post(this.addOrUpdateUrl, this.formatAddForm(this.model)).then(result => {
            this.handleEsc()
            if (!this.$listeners.afterAddOrUpdate) {
              this.search()
            }
            this.$emit('afterAddOrUpdate', result.data, this.search)
            this.$refs.dialog.close()
          })
        }
      })
    },
    handleSelection(val) {
      this.selection = val
    },
    // 分页开始
    handleCurrentChange(val) {
      this.searchAddForm.page = val
      this.search()
    },
    handleSizeChange(val) {
      this.searchAddForm.page = 1
      this.searchAddForm.size = val
      this.search()
    },
    // 分页结束
  }
}
</script>

<style>
.NumFooter{
  padding: 20px 20px 0px;
  text-align: right;
}
</style>
