<template>
  <div id="simpleTable">
    <slot name="info" />
    <el-table v-bind="$attrs" :data="data" highlight-current-row border style="width: 100%;"
              :loading="$store.state.isLoading" @row-dblclick="handleEdit" @selection-change="handleSelectionChange"
    >
      <el-table-column v-if="isSelection" type="selection" align="center" :selectable="selectable" />
      <el-table-column v-for="(item,index) in showForm.filter((form)=>{if(form.hidden){return false}else{return true}})"
                       :key="index"
                       :width="item.width"
                       :prop="item.param"
                       :label="item.name"
                       :render-header="item.renderHeader"
                       align="center"
      >
        <template slot-scope="scope">
          <SimpleTableWrapper :show-form="item" :data="scope.row" />
        </template>
      </el-table-column>
      <el-table-column v-if="typeof showOperation==='function'?showOperation():showOperation" align="center" label="操作" :width="operationWidth">
        <template slot-scope="scope">
          <el-button-group>
            <slot name="operation" :row="scope.row" />
            <el-button v-if="typeof canEdit==='function'?canEdit(scope.row):canEdit" :key="scope.row.id" type="primary" size="small" @click="handleEdit(scope.row,data)">
              编辑
            </el-button>
            <el-button v-if="typeof canDelete==='function'?canDelete(scope.row):canDelete" type="danger" size="small" @click="handleDelete(scope.row,data)">
              {{ deleteName }}
            </el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import SimpleTableWrapper from './SimpleTableWrapper'
export default {
  components: { SimpleTableWrapper },
  props: {
    data: { type: Array, required: false, default: () => [] },
    showForm: { type: Array, default: () => [], required: false },
    operationWidth: { required: false, type: String, default: '200px' },
    isSelection: { required: false, type: Boolean, default: false },
    selectable: { type: Function, default: () => {}, required: false },
    showOperation: { type: [Function, Boolean], default: () => true, required: false },
    canEdit: { type: [Function, Boolean], default: () => true, required: false },
    canDelete: { type: [Function, Boolean], default: () => true, required: false },
    deleteName: { required: false, type: String, default: '删除' },
    handleEdit: { type: Function, default: () => {}, required: false },
    handleDelete: { type: Function, default: () => {}, required: false },
  },
  methods: {
    handleSelectionChange(val) {
      this.$emit('selection', val)
    }
  }
}
</script>
