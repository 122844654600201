<script>
export default {
  name: 'SimpleTableWrapper',
  props: {
    data: { type: Object, required: false, default: () => {} },
    showForm: { type: Object, default: () => {}, required: false },
  },
  render(createElement, context) {
    const myBind = {
      props: {
        ...this.$attrs,
        ...this.showForm,
      },
      on: {
        ...this.$listeners,
        input: this.showForm.input
      }
    }
    switch (this.showForm.tableRowType) {
    case 'input' :return <el-input vModel={this.data[this.showForm.param]} {...myBind} maxlength={myBind.props.maxlength} placeholder={this.showForm.name} />
    case 'select' :
      return <el-select {...myBind} vModel={this.data[this.showForm.param]} placeholder={this.showForm.name}>
        {this.showForm.list(this.data).map(item => {
          return <el-option key={item.value} value={item.value} label={item.label} >{item.content ? item.content : item.label}</el-option>
        })}
      </el-select>
    case 'number' :return <el-input-number {...myBind} size="mini" v-model={this.data[this.showForm.param]} controls={false}/>
    default:return this.showForm.formatter ? <span>{this.showForm.formatter(this.data)}</span> : <span>{this.data[this.showForm.param]}</span>
    }
  }
}
</script>
